(function($){
	Common = {
		init: function() {
			console.log("A");
			this.smoothScroll()
			this.scrollMagic()
			this.addSlick()
			this.share()
		},
		share: function(){
			$('#share a').on('click', function(e){
				e.preventDefault()
				window.open($(this).attr('href'),"social_window","width=500,height=800,resizable=yes,scrollbars=yes,toolbar=yes")
			})
		},
		addSlick: function(){
			$('.intro-slide').slick({
				infinite: true,
				dots: false,
				arrows: false,
				autoplay: true,
				autoplaySpeed: 2000,
				speed: 700,
				infinite: true,
				fade: true,
				cssEase: 'ease-in-out',
			})
		},
		scrollMagic: function(){
			var controller = new ScrollMagic.Controller();
			var targets = $('.scrollmagic');
			var scenes = [];

			targets.each(function() {
				var target = this;
				var scene = new ScrollMagic.Scene({triggerElement : target, triggerHook : 0.75});
				scene.on('enter', function(e) {
					$(target).addClass('magic')
				});
				scene.on('leave', function(e) {
					// $(target).removeClass('magic')
				});
				scenes.push(scene);
			});
			controller.addScene(scenes);
		},
		smoothScroll: function() {
			$(document).on('click', 'a[href^="#"]', function(event) {
				event.preventDefault();
				var plus = 0;
				$(window).innerWidth() <= 767 ? plus = 50 : plus = 70;
				$('html, body').animate({
					scrollTop: $($.attr(this, 'href')).offset().top - plus
				}, 500);
			});
		},
	}

	$(function() {
		Common.init()
	})
})(jQuery)
